.omc-ukcgraph {
    font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif;
    font-size: 12px;
    color: #fff;
    line-height: 1 !important;
}

.omc-ukcgraph .axisLabels,
.omc-ukcgraph .flot-x-axis,
.omc-ukcgraph .flot-y-axis,
 .omc-ukcgraph .waypoint-label,
 .omc-ukcgraph .label {
  color: #fff !important;
  fill: #fff !important;
 }

 .omc-tooltip {
  color: #000;
  background-color: #fff;
}

.omc-title {
  display: none;
}

.omc-generated-time {
  display: none;
}

.omc-legend {
    padding: 0px;
    margin: 10px auto;
    font-size: 11px;
}

.omc-legend .legendLayer .background {
  fill: transparent;
}

.omc-legend .legendLayer text {
  fill: #fff;
}

.omc-ukcgraph .legendLayer tspan.disabled {
	fill: #9E9E9E;
}

.omc-ukcgraph .legendLayer tspan {
	cursor: pointer
}

.omc-link {
  font-size: 11px;
  display: flex;
  flex-direction: row-reverse;
}

div.omc-transittable tr:nth-child(even) {
  background-color: #444f63;
}

div.omc-transittable tr.bcBreach td {
  background-color: #ED1C24;
  color: white;
}

div.omc-transittable tr.mmBreach td {
  background-color: rgba(255, 165, 0, 0.40);
  color: black;
}
div.omc-transittable th[title], div.omc-transittable td[title] {
  cursor: help;
}
div.omc-transittable div.S5 table {
  /* border-color: #000 !important; */
  max-width: 100%;
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
  /* font-size: 14px; */
  width: 100%;
  /* border: 1px solid #ddd; */
  border-collapse: separate;
  border-left: 0;
  border-radius: 4px;
  /* font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; */
  font-size: 14px;
  line-height: 20px;
}
div.omc-transittable div.S5 table th, div.omc-transittable div.S5 table td {
  padding: 10px 0px;
  line-height: 20px;
  text-align: center;
  vertical-align: top;
  /* border-left: 1px solid #000; */
  /* border-top: 1px solid #ddd; */
  /* border-top: 1px solid #000; */
  padding-left: 5px !important;
}
div.omc-transittable div.S5 table caption {
  font-weight: bold;
  display: none;
}
div.omc-transittable div.S5 table th {
  font-weight: bold;
}
div.omc-transittable div.S5 table thead th {
  vertical-align: bottom;
  border-bottom: 1px solid #FFF;
  background-color: #444f63;
  padding: 15px 0px;
}
/* div.omc-transittable div.S5 table tbody + tbody {
  border-top: 2px solid #ddd;
} */
div.omc-transittable div.S5 table caption + thead tr:first-child th, div.omc-transittable div.S5 table caption + tbody tr:first-child th, div.omc-transittable div.S5 table caption + tbody tr:first-child td, div.omc-transittable div.S5 table colgroup + thead tr:first-child th, div.omc-transittable div.S5 table colgroup + tbody tr:first-child th, div.omc-transittable div.S5 table colgroup + tbody tr:first-child td, div.omc-transittable div.S5 table thead:first-child tr:first-child th, div.omc-transittable div.S5 table tbody:first-child tr:first-child th, div.omc-transittable div.S5 table tbody:first-child tr:first-child td {
  border-top: 0;
}
div.omc-transittable div.S4 {
  margin: 0;
  display: table;
  width: 100%;
}
div.omc-transittable div.S4 table {
  /* font-family: Verdana; */
  font-size: 7pt;
  border-collapse: collapse;
  /* border-left: solid 1px Black; */
  width: 100%;
}
/* div.omc-transittable div.S4 table td.transit:last-child {
  border-right: solid 1px Black;
} */
div.omc-transittable div.S4 table td {
  /* font-family: Arial Narrow; */
  padding: 0px;
}
div.omc-transittable div.S4 div.transit {
  display: table-cell;
  /* float: left;
  width: 33%; */
}
/* div.omc-transittable div.S4 div.transit:last-child {
  border-right: solid 1px Black;
} */
/* first 2 summary tables (ie skip 3rd one), put white border on the right. */
div.omc-transittable div.S4 div.transit:not(:last-child) table.summary td:last-child, div.omc-transittable div.S4 div.transit:not(:last-child) table.summary th:last-child {
  border-right: 1px solid #FFF;
}
/* only for the table below summary, we want the header separator, leave summary table unchanged. */
div.omc-transittable div.S4 table:not(.summary) thead th {
  border-top: 1px solid #FFF;
  border-bottom: 1px solid #FFF;
  background-color: #444f63;
}
div.omc-transittable div.S4 table td, div.omc-transittable div.S4 table th {
  text-align: center;
  /* border-top: solid 1px Black;
  border-bottom: solid 1px Black; */
}
div.omc-transittable div.S4 table th.profile {
  font-size: 11.25pt;
  font-weight: bold;
}
div.omc-transittable div.S4 .no-border {
  border: none !important;
}
/* div.omc-transittable div.S4 .b-r {
  border-right: solid 1px Black;
} */
