.react-datepicker {
    font-family: Gilroy, sans-serif;
}

.react-datepicker *, .react-datepicker *:focus,.react-datepicker *:hover{
    outline: none; /* override weird chrome default black box */
}

.react-datepicker__day {
    width: 36px;
    height: 36px;
    line-height: 36px;
    margin: 1px 6px;
    font-size: 18px;
    color: #667890;
    font-weight: 500;
    border: 1px solid transparent;
    border-radius: 50%;
    text-align: center;
}

.react-datepicker__day:hover,
.react-datepicker__day--highlighted:hover {
    background-color: #1f8cfa40;
}

.react-datepicker__day:hover {
    border-radius: 50%;
}

.react-datepicker__day--keyboard-selected {
    background-color: transparent;
}

.react-datepicker__day--today {
    border-color: transparent;
    background-color: transparent;
}

.react-datepicker__day--highlighted {
    border-color: rgba(149, 166, 182, 0.27);
    background-color: transparent;
    color: #667890;
}

.react-datepicker__day--selected {
    background-color: #95A6B6;
    border-color: transparent;
    color: white;
}

.react-datepicker__day--selected:hover {
    background-color: #95A6B6;
}

.react-datepicker__day--outside-month {
    opacity: 0.25;
}

.react-datepicker__day-name {
    font-size: 0;
    width: 36px;
    height: 36px;
    line-height: 36px;
    margin: 0 6px;
    text-align: center;
    font-weight: 600;
}

.react-datepicker__day--in-range {
    background-color: #1f8cfa40; 
    color: #667890;
}

.react-datepicker__day--in-selecting-range {
    background-color: #1f8cfa40; 
    color: #667890;
}

.react-datepicker__day-name:first-letter {
    font-size: 20px;
    text-transform: uppercase;
    color: #95A6B6;
}

.react-datepicker__header {
    background-color: transparent;
    border: 0;
    margin: 16px 16px 0 16px;
}

.react-datepicker__current-month {
    text-align: left;
    text-transform: uppercase;
    color: #95A6B6;
    font-size: 16px;
    font-weight: 500;
    position: relative;
    top: -3px;
    left: 12px;
    height: 28px;
}

.react-datepicker__navigation {
    transform: scale(1.3);
    top: 20px;
}

.react-datepicker__navigation--previous {
    right: 72px;
    left: initial;
    border-right-color: #1F8EFA;
}

.react-datepicker__navigation--next {
    right: 30px;
    left: initial;
    border-left-color: #1F8EFA;
}

.react-datepicker__month {
    margin: 0 16px 16px 16px;
}

.react-datepicker-popper[data-placement^="bottom"] {
    margin-top: 0;
}