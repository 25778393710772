@media print {
    .unbreakable {
        display: inline-block;
    }

    .unbreakable:after {
        content: '';
        display: block;
        height: 0;
        visibility: hidden;
    }
}